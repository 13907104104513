import $ from 'jquery';
import 'selectize';

$(document).on('turbolinks:before-cache', function() {
  $('.selectized').each(function(){
    if (this.selectize != undefined) {
      this.selectize.destroy()
    }
  })
});

$(document).on(('turbolinks:load'), () => {

  var surgeons = []

  if ($('#clinical_case_surgeon').length) {
    $.ajax({
      type: "GET",
      url: "/surgeons",
      success: function(response) {
        response.data.forEach(function(surgeonData) {
          surgeons.push({
            id: surgeonData.id,
            name: surgeonData.name,
            new_surgeon: false
          })
        })

        $('#clinical_case_surgeon').selectize({
          create: true,
          maxItems: 1,
          valueField: 'id',
          labelField: 'name',
          searchField: ["name"],
          options: surgeons,
          render: {
            item: function(item, escape) {
                return '<div>' +
                    (item.name ? '<span class="name">' + escape(item.name) + '</span>' : '')
                '</div>';
            },
            option: function(item, escape) {
                var label = item.name
                return '<div class="container">' +
                    '<div class="has-text-weight-bold">' + escape(label) + '</div>' +
                '</div>';
            }
          },
          onChange: function(value) {
            var selectedSurgeon = {};
            surgeons.forEach(function(surgeon) {
              if(value == surgeon.id)
              {
                selectedSurgeon = surgeon;
              }
            })
            if (selectedSurgeon) {
              $("#clinical_case_surgeon_id").val(value);
              $("#clinical_case_new_surgeon").val(selectedSurgeon.new_surgeon);
            }
            else {
              return false
            }
          },
          create: function(value) {
            $("#clinical_case_surgeon").val(value);
            $("#clinical_case_new_surgeon").val(true);
            
            var surgeon_object = {
              id: value,
              name: value,
              new_surgeon: true
            }

            surgeons.push(surgeon_object);
            // NOTE: Id has to be unique so we set it 0_value to allow the user to change their mind
            // NOTE: In ruby we will check for the _
            return surgeon_object;
          }
        });
      }
    })
  }

})