import bulmaCalendar from "../utilities/bulma/bulma-calendar"

$(document).on(('turbolinks:load'), () => {
  if ($('#professional_course_start_date_of_event').length) {
    let default_date = new Date();
    const date_val = $("professional_course_start_date_of_event").val();
    if(date_val && date_val != ""){
      let default_date = new Date($("#professional_course_start_date_of_event").val())
    }
    let offset = default_date.getTimezoneOffset()
    $("#professional_course_start_date_of_event").val(new Date(default_date + offset));
    let options = { 
      startDate: (new Date(default_date.getTime() + (offset * 1000 * 60))), 
      "minDate": new Date(1990,1,1),
      "maxDate": new Date(),
      "color": "#000858", 
      "dateFormat": "MM/DD/YYYY", 
      "showHeader": false, 
      "showButtons": false, 
      "showFooter": false, 
      "showClearButton": false }
    const calendars = bulmaCalendar.attach('[type="date"]', options);

    var element = calendars[0];
    if (element) {
      $(".datetimepicker-clear-button").hide();
      element.on('select', function(datepicker) {
        $("#professional_course_start_date_of_event").trigger("change")
      });
    }
  }
})