$(document).on("turbolinks:load", function() {
  $(".navbar__burger").click(function() {
    $(".navbar__burger").toggleClass(["is-active -active"])
    $(".navbar__menu").toggleClass("is-active")
    $(".navbar__brand").toggleClass("-active")
    $(".navbar").toggleClass("-active")
    $(".navbar__profile_container").toggle()
    $("section").toggleClass("-no-scroll")
    $("body").toggleClass("-no-scroll")
  })
})
